import React, { useEffect, useState } from "react";
import "./styles/dashboard.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import "./styles/dashboard.css";


import CreatableSelect from "react-select/creatable";

const AddBooking = () => {
  const navigate = useNavigate();

  const [pickLoc, setPicLoc] = useState([]);
  const [drop, setDrop] = useState([]);
  const [times, setTimes] = useState([]);
  const [agency, setAgency] = useState([]);
  let trigger = true;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/suggests/getagencies?da=` + new Date(), {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.list) {


        if (trigger) {
          resp.list.forEach((value) => {
            agency.push({ value: value.agency_name, label: value.agency_name });
          });

          trigger = false;
        }



      }
    });

    fetch(`${process.env.REACT_APP_API_URL}/suggests/getpickuplocations?da=` + new Date(), {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.list) {
        setPicLoc(resp.list);
      }
    });

    fetch(`${process.env.REACT_APP_API_URL}/suggests/getdroppoints?da=` + new Date(), {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      console.log(resp);

      if (resp.list) {
        setDrop(resp.list);
      }
    });

    fetch(`${process.env.REACT_APP_API_URL}/suggests/getbustimings?da=` + new Date(), {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: [],
    }).then(async (res) => {
      let resp = await res.json();
      if (resp.message === "OK") {
        setTimes(resp.list);
        console.log(resp);
      } else {
        toast.error(resp.message);
      }
    });
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    const data = new URLSearchParams();
    for (const pair of new FormData(
      document.getElementById("addBookingForm")
    )) {
      data.append(pair[0], pair[1]);
    }

    data.set(
      "flight_time",
      document.getElementById("flight-time").value.replace("T", " ")
    );

    fetch(`${process.env.REACT_APP_API_URL}/booking/addbooking`, {
      method: "post",
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: data,
    }).then(async (res) => {
      let resp = await res.json();

      console.log(resp);

      if (resp.message === "Added") {
        toast.success("Booking created");
        navigate("/bookings");
      } else {
        toast.error(resp.message);
      }
    });
  }

  return (
    <div
      style={{ margin: "3rem 2rem", padding: "1rem", backgroundColor: "white" }}
      className="card shadow border-0"
    >
      <form class="row g-3" onSubmit={handleSubmit} id="addBookingForm">
        <div class="col-12">
          <label class="form-label">Pax Name</label>

          <input
            type="text"
            class="form-control shadow-none"
            name="pax_name"
            placeholder="Enter pax name"
          />
        </div>
        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Mobile No.
          </label>
          <input
            type="text"
            class="form-control shadow-none"
            name="pax_contact_no"
            placeholder="Enter pax contact number"
          />
        </div>

        <div class="col-12" style={{ position: "relative" }}>
          <label for="inputAddress" class="form-label">
            Agency Name
          </label>

          {/*   <input
            autoComplete="off"
            id="agency_name"
            type="text"
            class="form-control shadow-none"
           name="agency_name"
            placeholder="Enter agency name"
  /> */}

<CreatableSelect
            isClearable
            options={agency}
            placeholder="Enter agency name"
            id="agency_name"
            name="agency_name"
          />


        </div>

        <div class="col-12">
          <label for="inputAddress2" class="form-label">
            Pickup Point
          </label>

          {/* <input
            type="text"
            class="form-control shadow-none"
            name="role_fk"
            id="inputAddress2"
            placeholder="Enter user role"
          /> */}

          <select class="form-select shadow-none" name="pickup_location_name">
            <option selected value={""}>
              Choose...
            </option>
            {pickLoc.length !== 0 ? (
              pickLoc.map((role) => {
                return (
                  <option value={role.pickup_point_name}>
                    {role.pickup_point_name}
                  </option>
                );
              })
            ) : (
              <option value={""}>...</option>
            )}
          </select>
        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Sub Pickup Point
          </label>

          <input
            type="text"
            class="form-control shadow-none"
            name="sub_pickup"
          />
        </div>


        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Pickup Date
          </label>

          {/* <input
            type="text"
            class="form-control shadow-none"
            name="role_fk"
            id="inputAddress2"
            placeholder="Enter user role"
          /> */}

          <input
            type="date"
            class="form-control shadow-none"
            name="pickup_date"
          />
        </div>

        

        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Drop Point
          </label>

          <select class="form-select shadow-none" name="drop_point_name">
            <option selected value={""}>
              Choose...
            </option>
            {drop.length !== 0 ? (
              drop.map((role) => {
                return (
                  <option value={role.drop_point_name}>
                    {role.drop_point_name}
                  </option>
                );
              })
            ) : (
              <option value={""}>...</option>
            )}
          </select>
        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Flight Time
          </label>

          {/* <input
            type="text"
            class="form-control shadow-none"
            name="role_fk"
            id="inputAddress2"
            placeholder="Enter user role"
          /> */}

          <input
            type="datetime-local"
            class="form-control shadow-none"
            name="flight_time"
            id="flight-time"
          />
        </div>

        <div class="col-md-6">
          <label for="inputAddress2" class="form-label">
            Bus Time
          </label>

          <select class="form-select shadow-none" name="bus_time_id">
            <option selected value={""}>
              Choose...
            </option>
            {times.length !== 0 ? (
              times.map((role) => {
                return <option value={role.time_id}>{role.time_name}</option>;
              })
            ) : (
              <option value={""}>...</option>
            )}
          </select>
        </div>

        <div class="col-12">
          <label for="inputAddress" class="form-label">
            Remarks
          </label>
          <input
            type="text"
            class="form-control shadow-none"
            name="remarks"
            placeholder="Optional"
          />
        </div>

        <div class="col-12 mt-8">
          <button
            class="btn d-inline-flex btn-sm btn-neutral mx-1"
            onClick={() => navigate("/bookings")}
          >
            <span class=" pe-2">
              <i class="bi bi-arrow-left"></i>
            </span>
            <span>Back</span>
          </button>
          <button type="submit" class="btn btn-primary btn-sm mx-1">
            Add Booking
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddBooking;
